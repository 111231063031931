export const posts = [
    {
      "title": "Entrepreneurship @ Western University: An Opinion Piece",
      "content": [
        {
          "type": "paragraph",
          "text": "My experience as a founder at Western University has not always been smooth sailing. I spent my first year of university feeling quite disconnected from the entrepreneurial scene, and the majority of my second year advocating for budding entrepreneurs and providing them the support that I wish I had."
        },
        {
          "type": "paragraph",
          "text": "As a result, I’ve developed a critical lens on the areas of need in Western’s current entrepreneurial ecosystem and have become empowered to address them. This post is my vision of how I hope to see entrepreneurship at Western to grow over the next 3-5 years, as well as strategy for how we can make this happen."
        },
        {
          "type": "paragraph",
          "text": "This is a working document, so I would appreciate any comments or feedback! The last thing I mean to be is prescriptive :)"
        },
        {
          "type": "heading",
          "level": 2,
          "text": "\"Help, I'm Lost\""
        },
        {
          "type": "paragraph",
          "text": "Western has no shortage of entrepreneurial communities. Clubs like W5, WFN, Hult, and more are all vital hubs for students. Many students, including myself, quickly find these clubs after accepting their offer to Western University through Instagram or Google searches. They are the primary entry points to Western’s student entrepreneurial community."
        },
        {
          "type": "heading",
          "level": 2,
          "text": "Struggles with Entrepreneurial Resources"
        },
        {
          "type": "paragraph",
          "text": "Lately, I see a trend where student entrepreneurial clubs have been straying from their flagship events (e.g. incubators, fireside chats) in favour of more popular events that may be less directly related to entrepreneurship (e.g. consulting competitions, office tours). This is understandable; these newer events can be lucrative and stand to boost their popularity on campus. However, there was a reason these clubs were formed in the name of entrepreneurship in the first place. I think all they need  is a little bit of reminding."
        },
        {
          "type": "paragraph",
          "text": "From an individual perspective, the current landscape results in a lot of difficulties meeting fellow founders, nurturing individual's entrepreneurial drive, and fostering a closely knit entrepreneurship scene where we inspire one another. Though myself and others have been trying to bridge this disconnect, there is a deep need for some structural changes to take place."
        },
        {
          "type": "italics",
          "text": "How to bridge this?"
        },
        {
          "type": "paragraph",
          "text": "There needs to a unifying thread between these entrepreneurial organizations. Just as structures for individual accountability are important, large organizations like student clubs can benefit from each other's direction and drive towards building tomorrow's entrepreneurs."
        },
        {
          "type": "paragraph",
          "text": "I think that Morrissette could be perfectly positioned to help achieve this. Morrissette has a wealth of resources for Western entrepreneurs, from advisors to pitch competitions to mentors. The one thing they need more of is exposure to students, which is exactly what these clubs have. A crucial next step for both organizations would be to establish a symbiotic relationship, where Morrissette provides further resources to these student organizations, while they serve as a funnel directing entrepreneurial potential to the right place to grow. This could be on as low-commitment of a basis as possible for both parties, even just having monthly meetings with some club heads could be a good step."
        },
        {
          "type": "heading",
          "level": 2,
          "text": "Ideation Support"
        },
        {
          "type": "paragraph",
          "text": "I've talked to a lot of students with crazy potential. A common answer that I hear from why they shy away from entrepreneurship as a viable career is uncertainty—uncertainty about the value of their ideas, lack of confidence in their abilities, fear of failure, and more. These barriers are preventing a significant portion of the talent at Western from overcoming the stigma associated with entrepreneurship and going all in on their ideas."
        },
        {
          "type": "paragraph",
          "text": "As a result, their fantastic ideas remain just that: ideas."
        },
        {
          "type": "italics",
          "text": "How to bridge this?"
        },
        {
          "type": "paragraph",
          "text": "This is something that I’ve built Momentum specifically to combat. We are a coworking community designed to provide accountability sessions tailored for passionate people to pursue the projects they care about. No school allowed. Recently, we have been collaborating with VCs to provide ‘leap of faith’ grants for new builders. I find this concept especially important as this little investment is exactly what I've seen propel some of the most talented builders and founders. It just takes a little nudge."
        },
        {
          "type": "paragraph",
          "text": "If you’re reading this and feel like you have an interesting project, I would love to hear it and propel you as much as I possibly can."
        },
        {
          "type": "paragraph",
          "text": "Additionally, I've been in discussions with Morrissette (s/o Nick!) to transform Momentum into a space where everyone engaging with their programming can gather and nurture each other's passions. This aims to alleviate traffic overload from Morrissette's entrepreneurship advisors while retaining all these talented individuals."
        },
        {
          "type": "heading",
          "level": 2,
          "text": "Bonus: Western's Tech Scene"
        },
        {
          "type": "paragraph",
          "text": "I write this last point with a touch of selfishness, coming from my background as a tech founder and software engineer."
        },
        {
          "type": "paragraph",
          "text": "I've come to realize that the vast majority of founders that come out of Western University lie in the CPG/manufacturing space. It makes a lot of sense considering Western's status as a renowned business school and assignments like the 2257 Feasibility project that immerse students in the possibility of building a production enterprise. This leaves tech founders like myself turning our gaze to schools with a big tech entrepreneurship scene like Waterloo, and often dropping out or trying to transfer. For some like myself, this course of action is not possible, leaving us yearning to meet other Western-based tech founders in the same boat."
        },
        {
          "type": "italics",
          "text": "How to bridge this?"
        },
        {
          "type": "paragraph-link",
          "text": "This is one I plan to take on myself. I hope to lead by example by furthering my relentless pursuit to be a successful founder. I've had entrepreneurial experience in social impact fields (see: "
        },
        {
          "type": "link",
          "href": "https://westerngazette.ca/news/campus/usc-to-add-in-app-waste-sorting-page-collaborates-with-student-group/article_9ef62de4-8245-11ee-bf9c-f38039b5a84d.html",
          "text": "Greensort, "
        },
        {
          "type": "link",
          "href": "https://www.project5k.ca/",
          "text": "Project 5K"
        },
        {
          "type": "paragraph-link",
          "text": "), though in the long-term, I aim to develop successful tech companies as a technical founder. I'm working on this as we speak through building my first SaaS startup: Papertrail, an expense management tool for non-profits. However, this is my long-term vision taking over. In the short term, I've been supporting individual tech founders (shoutout Robin, Ryan, and more!) and been sharing the opportunities that come my way with others (e.g. "
        },
        {
          "type": "link",
          "href": "https://www.cansbridgescholars.com",
          "text": "Cansbridge connections, "
        },
        {
          "type": "link",
          "href": "https://www.socratica.info/",
          "text": "Socratica network"
        },
        {
          "type": "paragraph-startlink",
          "text": ", etc.)."
        },
        {
          "type": "paragraph",
          "text": "There is definitely more to be done. Going forward, I hope to see more engagement with successful Western alum in tech startups (e.g. Y-Combinator grads, talented CEOs like Braden Ream, etc.) to serve as inspiration to show Western students that flocking to bigger tech hubs is not the only answer."
        },
        {
          "type": "heading",
          "level": 2,
          "text": "Parting Thoughts"
        },
        {
          "type": "paragraph",
          "text": "Despite this being a discussion of how I hope to see Western entrepreneurship change, it's important to recognize how these come from a place of celebration. The need for these areas of growth indicate a growing sense of agency across Western student entrepreneurs. If you consider yourself part of this category, I implore you to reflect on how you what you hope to see in the future of Western's entrepreneurship scene. Hell, write a blog post about it! You've got at least one reader in me."
        },
        {
          "type": "paragraph",
          "text": "Ultimately, the future of Western entrepreneurship is so bright. I am beyond excited to see how it materializes, and I will continue doing my best to drive progress."
        },
        {
          "type": "paragraph",
          "text": ""
        },
        {
          "type": "italics",
          "level": 2,
          "text": "Special Thank You's To My Sounding Boards"
        },
        {
          "type": "paragraph",
          "text": "Nick Fox, Jasmine Virdee, Pratham Gupta, Anne Lekhi"
        }
      ]
    },
    {
      title: "Priorities — Summer 2024",
      content: [
        {
          type: "heading",
          level: 3,
          text: "My Priorities — Summer 2024"
        },
        {
          type: "paragraph",
          text: "Some jot notes on pushing myself to become a better founder"
        },
        {
          type: "paragraph",
          text: "Being a better Software Engineer/Technical Founder"
        },
        {
          type: "bullet",
          text: "Learn how to build simple LLMs on PyTorch"
        },
        {
          type: "bullet",
          text: "Succeed in my work at BorealisAI & understand the pain points of infrastructure engineers"
        },
        {
          type: "paragraph",
          text: "PaperTrail"
        },
        {
          type: "bullet",
          text: "Get PaperTrail’s first client"
        },
        {
          type: "bullet",
          text: "Build PaperTrail web app up for official launch"
        },
        {
          type: "bullet",
          text: "Revisit software pricing models"
        },
        {
          type: "paragraph",
          text: "Momentum"
        },
        {
          type: "bullet",
          text: "Get Momentum at least 2 more connections with VCs"
        },
        {
          type: "bullet",
          text: "Find Momentum a graphic designer"
        },
        {
          type: "bullet",
          text: "Find hosts for the Fall 2024 semester at Momentum"
        },
        {
          type: "paragraph",
          text: "Meeting Founders"
        },
        {
          type: "bullet",
          text: "Attend 1517 summit, Vancouver startup week, & Collision conference"
        },
        {
          type: "bullet",
          text: "Invite passionate founders to apply for Cansbridge programs"
        },
        {
          type: "paragraph",
          text: "Continue writing!"
        }
      ]  
    }
  ]
  
